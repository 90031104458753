<template>
  <div><Etest /></div>
</template>

<script>
export default {
  components: {
    Etest: () => import("./components/Etest.vue"),
  },
};
</script>
